/* You can add global styles to this file, and also import other style files */
//@import './assets/scss/fonts';
//@import './assets/scss/main';

@import './assets/cardif/scss/main.scss';
@import './assets/cardif/scss/custom.scss';
@import "./assets/scss/pagination.scss";
@import "./assets/scss/table.scss";
@import "./assets/scss/button.scss";
@import './assets/scss/modal.scss';
@import "./assets/scss/angular-ng-autocomplete.scss";
@import "./assets/scss/aui.scss";

//@import './app/components/pages/setinformativi/tablesetinformativi/tablesetinformativi.component.scss';
//@import './app/components/templates/spinner/spinner.component.scss';
//@import './app/components/templates/resultnotfound/resultnotfound.component.scss';