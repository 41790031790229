.aui .breadcrumb {
    padding: 8px 15px;
    margin: 0 0 20px;
    list-style: none;
    border-radius: 2px;
    background-color: transparent;
}

.advanced-search-button {
    font-size: 10px;
    margin: 0px 0px 0px 0px;
    line-height: 10px;
}
.advanced-search-button .txt-remove {
    color: #00965e;
    font-size: 14px;
    font-weight: bold;
}
.txt-remove {
    color: #495057;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;
}

#box-search-setinformativi.box-search {
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    height: 50px;
    margin-bottom: 40px;
    position: relative;
}

#box-search-setinformativi.box-search .searchInput {
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
    padding-left: 0px;
    padding-right: 24px;
    box-shadow: unset;
    border: 0;
    margin: 0;
    padding-left: 15px;
    width: 100%;
}

#box-search-setinformativi.box-search .btn-search {
    border: 0px;
    background: #fff;
    transform: translate(-50%, -50%);
    position: absolute;
    right: 0px;
    font-size: 20px;
    top: 50%;
}
button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer;
}
.aui label, .aui input, .aui button, .aui select, .aui textarea {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}
#box-search-setinformativi.box-search .search-img {
    height: 20px;
}

.aui textarea, .aui input[type=text], .aui input[type=password], .aui input[type=datetime], .aui input[type=datetime-local], .aui input[type=date], .aui input[type=month], .aui input[type=time], .aui input[type=week], .aui input[type=number], .aui input[type=email], .aui input[type=url], .aui input[type=search], .aui input[type=tel], .aui input[type=color], .aui .uneditable-input {
    background-color: white;
    border: 1px solid #cccccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border linear 0.2s, box-shadow linear 0.2s;
}
.clickable {
    cursor: pointer;
}

.clear-filter {
    text-align: end;
}











.chart-nav {
    position: relative;
    display: block;
    height: 329px
}

.block-info {
    background: #f8f9fa;
    border-color: #dee2e7;
    border-style: solid;
    border-width: 1px 0 1px 0
}

.block-info_detail {
    padding: 23px 30px
}

.block-info_detail ul {
    list-style: none;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between
}

.block-info_detail ul li {
    white-space: nowrap;
    overflow: hidden
}

.block-filter {
    padding: 35px 0
}

.block-filter_detail ul {
    list-style: none !important;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 20px
}

.block-filter_detail ul input[type=radio] {
    opacity: 0;
    position: fixed;
    width: 0
}

.block-filter_detail ul li:nth-child(n+4) {
    border: 1px solid #d6d7d8;
    height: 14px;
    box-sizing: border-box
}

.block-filter_detail label {
    display: inline-block;
    padding: 0 10px;
    font-family: sans-serif,Arial;
    font-size: 14px;
    line-height: 14px;
    border-radius: 4px;
    color: #040724;
    margin: 0
}

.block-filter_detail label.active {
    color: #179c98
}




.autocomplete-container .input-container input {
    padding-right: 31px !important;
}


@media screen and (max-width: 74.9375em) {
    .header__bottom .alwaysopen .sub-menu-special {
        list-style: none;
        padding: 1.25rem 0;
        text-align: center;
    }
}